<template>
    <div class="personal-info">
      <h3>Особисті дані</h3>
      <form @submit.prevent="submitPersonalInfo">
        <div class="form-group" :class="{ error: errors.firstName && touched.firstName }">
          <label>Ім'я:</label>
          <input v-model="personalInfo.firstName" @blur="touched.firstName = true" type="text" required />
          <span v-if="errors.firstName && touched.firstName" class="error-message">Ім'я</span>
        </div>
        <div class="form-group" :class="{ error: errors.lastName && touched.lastName }">
          <label>Прізвище:</label>
          <input v-model="personalInfo.lastName" @blur="touched.lastName = true" type="text" required />
          <span v-if="errors.lastName && touched.lastName" class="error-message">Прізвище </span>
        </div>
  
        <!-- Поле для вводу номера телефону зі статичним префіксом +38 -->
        <div class="form-group phone-input-wrapper" :class="{ error: errors.phone && touched.phone }">
          <label>Номер телефону:</label>
          <div class="phone-wrapper">
            <span class="prefix">+38</span>
            <input v-model="phoneNumber" @input="formatPhoneNumber" type="tel" required maxlength="13" placeholder="___-___-__-__" />
          </div>
          <span v-if="errors.phone && touched.phone" class="error-message">Невірний номер телефону</span>
        </div>
  
        <div class="form-group" :class="{ error: errors.email && touched.email }">
          <label>Email:</label>
          <input v-model="personalInfo.email" @blur="touched.email = true" type="email" required />
        </div>

  
        <div class="form-group">
          <button @click="handleNextStep" class="next-button">Далі</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  
  export default {
    name: 'PersonalInfo',
    data() {
      return {
        phoneNumber: '',  // Змінна для збереження номера телефону без префіксу
        errors: {},  // Для зберігання помилок валідації
        touched: {  // Для відстеження взаємодії з полями
          firstName: false,
          lastName: false,
          phone: false,
          email: false
        }
      };
    },
    computed: {
      ...mapState('checkout', ['personalInfo']),
      ...mapState('auth', ['user']),
    },
    methods: {
      ...mapActions('checkout', ['savePersonalInfo']),
      ...mapActions('auth', ['getUserInfo']),
  
      formatPhoneNumber() {
        // Видаляємо всі нецифрові символи, окрім цифр
        let rawNumber = this.phoneNumber.replace(/\D/g, '');
  
        // Переконуємося, що вводиться не більше 10 цифр (без префікса +38)
        if (rawNumber.length > 10) {
          rawNumber = rawNumber.slice(0, 10);
        }
  
        // Форматуємо номер як XXX-XXX-XX-XX
        if (rawNumber.length > 6) {
          rawNumber = `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 6)}-${rawNumber.slice(6, 8)}-${rawNumber.slice(8)}`;
        } else if (rawNumber.length > 3) {
          rawNumber = `${rawNumber.slice(0, 3)}-${rawNumber.slice(3)}`;
        }
  
        this.phoneNumber = rawNumber;
      },
  
      formatUserPhoneNumber(phone) {
        // Видаляємо всі символи, окрім цифр
        let rawNumber = phone.replace(/\D/g, '');
  
        // Якщо номер занадто довгий, відкидаємо зайві символи
        if (rawNumber.length > 10) {
          rawNumber = rawNumber.slice(-10);
        }
  
        // Форматуємо номер як XXX-XXX-XX-XX
        if (rawNumber.length > 6) {
          return `${rawNumber.slice(0, 3)}-${rawNumber.slice(3, 6)}-${rawNumber.slice(6, 8)}-${rawNumber.slice(8)}`;
        } else if (rawNumber.length > 3) {
          return `${rawNumber.slice(0, 3)}-${rawNumber.slice(3)}`;
        } else {
          return rawNumber;
        }
      },
  
      validateFields(showAll = false) {
      this.errors = {};  // Очищаємо помилки перед перевіркою
      let isValid = true;

      if (!this.personalInfo.firstName) {
        this.errors.firstName = true;
        isValid = false;
      }
      if (!this.personalInfo.lastName) {
        this.errors.lastName = true;
        isValid = false;
      }
      if (!this.phoneNumber || this.phoneNumber.replace(/\D/g, '').length !== 10) {
        this.errors.phone = true;
        isValid = false;
      }
      // Додаємо валідацію email
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.personalInfo.email || !emailPattern.test(this.personalInfo.email)) {
        this.errors.email = true;
        isValid = false;
      }

      if (showAll) {
        this.touched.firstName = true;
        this.touched.lastName = true;
        this.touched.phone = true;
        this.touched.email = true;
      }

      return isValid;
    },

      handleNextStep() {
        if (this.validateFields(true)) {
          // Зберігаємо номер у форматі +38XXXXXXXXXX
          const formattedPhone = '+38' + this.phoneNumber.replace(/\D/g, '');
          this.personalInfo.phone = formattedPhone;
          this.savePersonalInfo(this.personalInfo);  // Зберігаємо дані у Vuex
          this.$emit('nextStep');  // Сигналізуємо батьківському компоненту про перехід
        }
      }
    },
    watch: {
      user(newValue) {
        if (newValue) {
          this.personalInfo.firstName = newValue.first_name || '';
          this.personalInfo.lastName = newValue.last_name || '';
          if (newValue.phone_number) {
            // Форматуємо номер користувача
            this.phoneNumber = this.formatUserPhoneNumber(newValue.phone_number.slice(3));  // Видаляємо префікс +38
          }
          this.personalInfo.email = newValue.email || '';
        }
      }
    },
    mounted() {
      if (!this.user) {
        this.getUserInfo();
      }
    }
  };
  </script>
  
  <style scoped>
  .form-group {
    margin-bottom: 1.5rem;
    width: 500px;
  }
  
  .form-group input {
    width: 95%;
    padding: 0.75rem;
    border: 1px solid #dddddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .phone-wrapper {
    display: flex;
    align-items: center;
  }
  
  .prefix {
    margin-right: 8px;

  }
  .form-group.error input {
  border-color: #ff0000; /* Червоний колір для помилкового поля */
  }
  
  .phone-wrapper input {
    width: calc(100% - 40px); /* Зменшуємо ширину інпута, щоб врахувати префікс */
  }
  
  .form-group.error input {
    border-color: #ff0000;
  }
  
  .error-message {
    color: #ff0000;
    font-size: 0.875rem;
  }
  
  .form-group button {
      border: 1px solid black;
      text-transform: uppercase;
      background: #ffffff;
      border: 1px solid #000;
      cursor: pointer;
      transition: 0.35s;
      width: 40%;
      letter-spacing: 1px;
      color: #000;
      font-weight: 600;
      font-size: 12px;
      padding: 10px 15px;
      font-family: 'MaisonNeue';
      margin-bottom: 5px;
      transition: background-color 0.3s, color 0.3s;
    }
    .form-group button:hover {
      background-color: #000;
      color: #aaa;
      border: 1px solid black;

    }
  @media (max-width: 1120px){ 
    .form-group {
        width: 450px;
    }
  }
  @media (max-width: 980px){
    .personal-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .form-group {
        width: 860px;
    }
  }
  @media (max-width: 900px){ 
    .form-group {
        width: 800px;
    }
  }
  @media (max-width: 836px){ 
    .form-group {
        width: 560px;
    }
  }
  @media (max-width: 620px){ 
    .form-group {
        width: 480px;
    }
  }
  @media (max-width: 530px){ 
    .form-group {
        width: 400px;
    }
  }
  @media (max-width: 430px){ 
    .form-group {
        width: 380px;
    }
  }
  @media (max-width: 390px){ 
    .form-group {
        width: 320px;
    }
  }
  </style>
  