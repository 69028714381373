import instance from '@/api/axiosInstance';
import { setAuthToken, getAuthToken, removeAuthToken } from '@/utils/cookie.js';
import Cookies from 'js-cookie';

const auth = {
  namespaced: true,
  state: {
    token: getAuthToken() || '',
    refreshToken: Cookies.get('refresh_token') || '',
    user: null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      setAuthToken(token);
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
      Cookies.set('refresh_token', refreshToken, {
        expires: 7,
        sameSite: 'Lax', 
      });
    },
    setUser(state, user) {
      state.user = user;
    },
    logout(state) {
      state.token = '';
      state.refreshToken = '';
      state.user = null;
      removeAuthToken();
      Cookies.remove('refresh_token');
    },
  },
  actions: {
    async register({ commit, dispatch }, userData) {
      try {
        // Запит до API для реєстрації нового користувача
        const response = await instance.post('/api/v1/users/', userData);
        
        // Успішна відповідь з токеном і даними користувача
        const token = response.data.access;
        const refreshToken = response.data.refresh; 
        
        // Зберігаємо токени та користувача в стані
        commit('setToken', token);
        commit('setRefreshToken', refreshToken);
        
        // Отримуємо інформацію про користувача, якщо потрібно
        await dispatch('getUserInfo');

        return response;
      } catch (error) {
        console.error('Помилка реєстрації:', error);
        throw error;
      }
    },
    async login({ commit, dispatch }, credentials) {
      try {
        const response = await instance.post('/api/v1/jwt/create/', credentials);
        const token = response.data.access;
        const refreshToken = response.data.refresh; 
        commit('setToken', token);
        commit('setRefreshToken', refreshToken);
        await dispatch('getUserInfo'); // Виклик через dispatch для коректної роботи з namespaced
        return response;
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    },
    async logout({ commit }) {
      commit('logout');
    },
    async refreshToken({ commit, state, dispatch }) {
      try {
        const refreshToken = state.refreshToken;
        if (refreshToken) {
          const response = await instance.post('/api/v1/jwt/refresh/', { refresh: refreshToken });
          const newToken = response.data.access;
          commit('setToken', newToken); 
          return newToken;
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        dispatch('logout'); // Якщо помилка, логаутимо користувача
        throw error;
      }
    },
    async getUserInfo({ commit, state, dispatch }) {
      try {
        const token = state.token;
        if (token) {
          const response = await instance.get('/api/v1/users/me/', {
            headers: { Authorization: `Bearer ${token}` },
          });
          commit('setUser', response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          try {
            await dispatch('refreshToken'); // Оновлюємо токен
            return dispatch('getUserInfo'); // Повторюємо запит після оновлення токена
          } catch (refreshError) {
            dispatch('logout'); // Якщо оновлення токена не вдалося, логаутимо користувача
          }
        } else {
          console.error('Error fetching user info:', error);
          commit('logout');
        }
      }
    },
    async changePassword({ state }, { current_password, new_password }) {
      try {
        const token = state.token;
        if (token) {
          const response = await instance.post(
            '/api/v1/auth/change-password/',
            { current_password, new_password },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          return response;
        }
      } catch (error) {
        console.error('Помилка зміни паролю:', error);
        throw error;
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token;
    },
    currentUser(state) {
      return state.user;
    },
  },
};

export default auth;
