<template>
  <section class="subscrible">
    <div class="newsletter-section _container">
      <form @submit.prevent="submitForm">
        <div class="base-block">
          <div class="text-path">
            <h3>ПІДПИШІТЬСЯ НА НАШУ РОЗСИЛКУ!</h3>
          </div>
          <div class="form-path">
            <input v-model="email" type="email" placeholder="Введіть пошту для підписки" required />
            <span class="inputn_group_btn">
              <button class='subscr_btn' type="submit">Підписатись</button>
            </span>
          </div>
        </div>
        <p class="subscribe-description">
          Підписуючись, ви погоджуєтеся отримувати комерційні повідомлення від Niche Beauty Lab. Ви можете відписатися в будь-який момент, відвідавши нашу Політику конфіденційності, де пояснено, як це зробити.
        </p>
      </form>
    </div>
  </section>
</template>

<script>
import instance from '@/api/axiosInstance'; 

export default {
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async submitForm() {
try {
  // Надсилаємо запит на сервер
  const response = await instance.post('/api/v1/actions/subscriptions/', {
    email: this.email, // Надсилаємо тільки дані
  });

  // Перевіряємо відповідь
  if (response.status === 201) {
    alert('Дякуємо за підписку!');
    this.email = ''; // Очищення поля після успішної підписки
  } else {
    alert('Помилка: Не вдалося підписатися.');
  }
} catch (error) {
  alert('Сталася помилка. Спробуйте пізніше.');
}
}
  },
};
</script>
<style scoped>
.subscrible{    
  padding: 0 20px 20px 20px;
  background-color: #f9f9f9;
}
@media (max-width: 768px) { 
  .subscrible{
      background-color: #d4eb59;
      padding: 30px 20px;
  }

}
.newsletter-section{    

}
.base-block{
  display: flex;
  justify-content: space-around;
  align-items: baseline;
}
@media (max-width: 768px) { 
  .base-block {
      justify-content: center;
      flex-wrap: wrap;
  }

}
.text-path {
  width: 50%;
  /* text-align: center; */
}
@media (max-width: 768px) { 
  .text-path{
      width: auto;
  }

}
.text-path h3{
  font-weight: 900;
  font-size: 2.5rem;
  margin-bottom: 5px;
  text-align: center;
  line-height: 40px;
}
@media (max-width: 768px) {
  .text-path h3 {
      font-size: 1.5rem;
  }
}
.h4{
  margin-bottom: 15px !important;
  font-size: 2.5rem;
  font-weight: 100;
  text-align: center;
}
.h4 span{ 
  font-size: 2.5rem;
  font-weight: 900;
}
.form-path{
  display: flex;
  flex-wrap: wrap;
  
}
.subscribe-description {
  padding: 20px 10px 0;
  text-align: center;
  clear: both;
  margin: 0 auto;
  font-size: 12px;
  font-weight: 400;
  
  
}
.form-path input {
  max-width: 380px;
  text-align: center;
  display: block;
  font-size: 12px;
  line-height: 1.25;
  background: #fff;
  color: #000000;
  margin: 5px auto 10px !important;
  border: 1px solid #acacac;
  width: 100%;
  height: 35px;
  border-radius: 3px !important;
  padding: 0 1rem 0;
}
@media (max-width: 768px) {
  .form-path {
      display: flex;
      flex-wrap: wrap;
  }
}
.inputn_group_btn {
  padding: 8px 45px;
  text-transform: uppercase;
  background: #000;
  border: 1px solid #000;
  cursor: pointer;
  transition: 0.35s;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  font-family: 'MaisonNeue';
  min-height: 34px;
  margin: 0 auto;
  display: block;
  max-width: 100%;
  background: transparent;
  border: 0;
}
.inputn_group_btn button {
  padding: 10px 15px;
  text-transform: uppercase;
  background: transparent;
  border: 1px solid #000;
  cursor: pointer;
  transition: 0.35s;
  margin: 10px auto;
  width: 100%;
  letter-spacing: 1px;
  color: #000;
  font-weight: 600;
  font-size: 12px;
  font-family: 'MaisonNeue';
  display: block;
}
.subscr_btn:hover {
background-color: #000; 
color: #fff; 
}

</style>